import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
const ArticlePage = () => {
    const location = useLocation();
    const { content,author,description,publishedAt,title,url,urlToImage} = location.state || {};
 
    console.log(location) 
    if (!content) {
        return <div>No se encontró el artículo.</div>;
    }

    return (
        <div>
            <div  className="font-sans  tracking-normal  flex items-center justify-center">
            <div  className="bg-white shadow-lg rounded-lg p-8 max-w-2xl w-full">
                <h1  className="text-3xl font-bold mb-4">{title}</h1>
                <div  className="flex items-center mb-4">
                 
                <div>
                    <p  className="text-gray-700 text-sm">By <span  className="font-semibold">{author}</span></p>
                    <p  className="text-gray-500 text-xs">Published on <span  className="font-semibold">{publishedAt}</span></p>
                </div>
                </div>
                <img src={urlToImage} alt={title}  className="w-full h-64 object-cover rounded-lg mb-6"/>
                <p  className="text-gray-700 mb-4">{description}</p>  

                <div  className="flex justify-between items-center mt-6">
          

                <Link to="/"  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-400 transition-all duration-300 ease-in-out">
                       Inicio
                </Link> 
                <Link to={url} target='_blank' className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-200 transition-all duration-300 ease-in-out">
                       Leer Post Completo
                </Link> 


                </div>

                 
            </div>
            </div>
        </div>
    );
};

export default ArticlePage;
