 
import { useState, React,useEffect } from 'react';  
import Modal from './Modal';  
 
const Header = () => { 
    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isPlaying, setIsPlaying] = useState(false);
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    }; 
     
    const handlePlayButtonClick = () => {
      const audioElement = document.querySelector('audio');
      if (audioElement) {
          if (isPlaying) {
              audioElement.pause();
              setIsPlaying(false);
              console.log('Paused:', false);
          } else {
              audioElement.play().then(() => {
                  setIsPlaying(true);
                  console.log('Playing:', true);
              }).catch((error) => {
                  console.error("Error playing audio:", error);
              });
          }
      } else {
          console.log('Audio element not found');
      }
  };
    
  return (  
<div> 
          <header class="text-center py-3">   
          <div>  
            <h1 className='text-white text-wrap glitch md:text-8xl text-5xl'>-NEGRO SERIAS  - </h1> 
          </div>  <br />
          <a href="#" onClick={handleOpenModal} className='mb-3 px-7 py-3 inline-block text-white rounded-full bg-red-900  hover:bg-rose-600 md:text-5xl glitch'>Solicitar Acceso</a>
          <a href="#" onClick={handlePlayButtonClick} className='ml-2 mb-3 px-7 py-3 inline-block text-white rounded-full bg-yellow-500  hover:bg-green-800 md:text-5xl glitch'>
          {isPlaying ? 'Radio On 📻':' Play Music 📻'} </a>
          </header>   
          <Modal isOpen={isModalOpen} onClose={handleCloseModal}> 
          <div>
          <h2 className='mb-3 text-wrap glitch md:text-6xl text-4xl'>NEGRO<span className='text-red-700 '>SERIAS</span></h2> 
          <hr/>
           <div  className='py-5 md:text-2xl '>
            <p>Hola: Bienvenido</p>
            <p className='mb-2'>Hay dos formas para unirte a nuestro Equipo:</p>
            <p>👉Mediante la donación de alguna Crypto (No hay Limite)💸</p>
            <p>👉unirte y Compartir nuestro Canal en  Telegram <a href='https://t.me/n3groserias' target='_blank' className='bg-slate-400 rounded-full px-2'>@n3groserias</a> 👌</p>
            <p className='mt-2'>Para ingresar a nuestro Chat Room Secreto es necesario <br/>que leas el post fijado en nuestro canal de telegram</p>
            <p className='bg-green-300 mt-4 rounded p-4'>Si realizaste tu donación escribenos a <strong>donaciones@negroserias.org</strong></p>
           </div>
            <button onClick={handleCloseModal} className="hover:bg-rose-500 bg-rose-200  py-4 w-full rounded">
                Cerrar  
            </button>
          </div>
        </Modal>
    </div>
  );
};

export default Header;
