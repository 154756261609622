import React from 'react';
import AudioPlayer from './Player'; 
import SeoComponent from './Seo';
import SidebarRight from './SidebarRight'; 
import Header from './Header'; 
const Layout = ({ children }) => {
  const currentYear = new Date().getFullYear();
    return (
      <div className='bg-ng'> 
      <div className='md:w-1/2 mx-auto '>
        <Header />
        <main className=''>
          <div> 
            <div className='Sidebar '>
            <SidebarRight />
            </div>
          {children}
          </div>
         
        </main>
        <footer className='mb-40  text-white'><p className='py-5'>Make with ❤️  {currentYear}</p></footer><hr /><br/>
        <AudioPlayer /> 
        
      </div>
      <SeoComponent
          title="Negroserias -  Música"
          description="Descubre y comparte tu pasión por la música. Únete a nuestro portal para explorar diversos géneros musicales, conocer nuevos artistas como Armin van Buuren en trance y Daft Punk en electro, y participar en conversaciones apasionadas sobre temas populares como 'Strobe' y 'Adagio for Strings'."
          keywords="música, géneros musicales, trance, electro, Armin van Buuren, Daft Punk, Strobe, Adagio for Strings, música en línea, comunidad musical"
          canonical="https://negroserias.org"
          author="Tu Nombre"
          ogTitle="Portal de Música"
          ogDescription="Descubre y comparte tu pasión por la música. Únete a nuestro portal para explorar diversos géneros musicales, conocer nuevos artistas como Armin van Buuren en trance y Daft Punk en electro, y participar en conversaciones apasionadas sobre temas populares como 'Strobe' y 'Adagio for Strings'."
          ogImage="https://negroserias.org/og-image.jpg"
          ogUrl="https://negroserias.org"
        />
      </div>
    );
  };
  
  export default Layout;