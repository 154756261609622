
import React from 'react';  
import { useState } from 'react'; 
import NewsComponent from './news'; 
const Noapi = () => {  


  return (
    <div className='md:container md:mx-auto'> 
      <div>
        <div className="siderbar px-1">  
          <hr className='my-2'/>
           
        </div>  
     </div> 
    </div>
    
  );
};

export default Noapi;
