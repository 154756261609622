import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';

const MarkdownViewer = ({ markdownContent }) => { 
  return (
   <div className="w-full bg-white p-5 rounded shadow ">
    <Link to="/" className='bg-slate-300 p-4 rounded-full  block'>👈 Ir a Inicio</Link> 
    <div className='mt-3 prose'><ReactMarkdown>{markdownContent}</ReactMarkdown></div>
    </ div>
  );  
};

export default MarkdownViewer;
