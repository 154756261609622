import React, { useState, useEffect } from 'react';
import './App.css'; 
//import SidebarRight from './components/SidebarRight';
//import SidebarLeft from './components/SidebarLeft'; 
//import SidebarCenter from './components/SidebarCenter';
//import { AuthProvider } from './AuthContext';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MarkdownViewer from './components/Markdown'; 
import Noapi from './components/Noapi';
//import Inicio from './components/Start';
import ArticlePage from './pages/ArticlePage';
import Layout from './components/Layout'; 

 const Home = () => {
 return ( 
      <div>  
        <Noapi/> 
      </div> 
 )
}
/*
<AuthProvider>      
      <div className="container mx-auto flex flex-col md:flex-row"> 
        <SidebarLeft />
        <SidebarCenter />
        <SidebarRight/>
      </div>
    </AuthProvider>
*/
const Term = () => {
  const [fileContent, setFileContent] = useState('');

  useEffect(() => {
    // Cargar el contenido del archivo aquí (puedes usar Fetch, Axios, etc.)
    fetch('/data/terminos.md') // Utiliza la variable que contiene la ruta al archivo
      .then(response => response.text())
      .then(data => setFileContent(data))
      .catch(error => console.error('Error cargando el archivo:', error));
  }, []); 
  return (
    <div className="container mx-auto flex flex-col md:flex-row">
    <MarkdownViewer markdownContent={fileContent} />  
    </div>
  )
 }


function App() { 
  return (  
    <BrowserRouter>
    <Layout>
      <Routes> 
            <Route index element={<Home />} /> 
            <Route path="/terminos" element={<Term />} /> 
            <Route path="/article/:id" element={<ArticlePage />} />
       </Routes>
      </Layout>
    </BrowserRouter> 
  );
}

export default App;
