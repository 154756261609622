import React, { useRef, useEffect, useState} from 'react';
 import P5Wrapper from './P5Wrapper' 
import { IconPause,IconPlay,IconNext,IconPrev } from './Icons';
// Helper function to format time in MM:SS format
const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
  
const radioStations = [
  {
    id: 1,
    name: "Eurodance - EDM - Trance",
    cover: "cover.jpg",
    listen: "https://happyhardcore-high.rautemusik.fm/stream.mp3"
  },
  {
    id: 2,
    name: "Eurodance",
    cover: "cover.jpg",
    listen: "https://listen1.myradio24.com/5967"
  },
  {
    id: 3,
    name: "Raute Musik HappyHardcore",
    cover: "cover.jpg",
    listen: "https://happyhardcore-high.rautemusik.fm/stream.mp3"
  },
  {
    id: 4,
    name: "El Bachatazo Radio Show",
    cover: "cover.jpg",
    listen: "https://stream.zenolive.com/wzdgb910t9duv"
  },
  {
    id: 5,
    name: "TDI Rradio",
    cover: "cover.jpg",
    listen: "http://streaming.tdiradio.com:8000/tdiradio"
  }
  ,
  {
    id: 6,
    name: "Musica Fresca y Romantica en Ingles",
    cover: "cover.jpg",
    listen: "https://antena.albaniadigitalradio.al/loveradio"
  }
  ,
  {
    id: 7,
    name: "Radio Café",
    cover: "cover.jpg",
    listen: "http://live.radiocafe.ro:8048/listen.pls"
  },
  {
    id: 8,
    name: "Fantasy Radio",
    cover: "cover.jpg",
    listen: "https://italo.live-streams.nl/"
  }
  ,
  {
    id: 9,
    name: "Hotmix Spain",
    cover: "cover.jpg",
    listen: "https://n17a-eu.rcs.revma.com/yn61n0hmd5uvv"
  },
  {
    id: 10,
    name: "Estación Latina",
    cover: "cover.jpg",
    listen: "https://n07a-eu.rcs.revma.com/et4wata2s7uvv"
  }

];
const AudioPlayer = () => {
  const audioRef = useRef(null);   
  const fieldsetRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const [elapsedTime, setElapsedTime] = useState(0);  
  const [fieldsetWidth, setFieldsetWidth] = useState(0); 
  const [currentStationIndex, setCurrentStationIndex] = useState(0);

  const [audioContext, setAudioContext] = useState(null);
  const [analyser, setAnalyser] = useState(null);

  useEffect(() => {
    const audioElement = audioRef.current;
    // console.log('as');
    if (fieldsetRef.current) {
      setFieldsetWidth(fieldsetRef.current);
    }
    // Evento paffta pausar el audio
    const handlePause = () => {
      setIsPlaying(false);
     // console.log('Audio pausado');
    }; 
    // Evento para reproducir el audio
    const handlePlay = () => {    
      setIsPlaying(true);  
      // Check if audioContext and analyser already exist
      if (!audioContext || !analyser) {
        const newAudioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = newAudioContext.createMediaElementSource(audioElement);
        const newAnalyser = newAudioContext.createAnalyser();
              newAnalyser.fftSize = 256 ;//(32, 64, 128, 256, 512, 1024, 2048, etc.).
              newAnalyser.connect(newAudioContext.destination); 
              setAnalyser(newAnalyser);  
              source.connect(newAnalyser);
              setAudioContext(newAudioContext); 
      } 
    }; 
    // Evento para cambiar el volumen del audio
    const handleVolumeChange = () => {
      const newVolume = audioElement.volume;
      setVolume(newVolume); 
    }; 
    // Evento para actualizar el tiempo transcurrido
    const handleTimeUpdate = () => {
      setElapsedTime(audioElement.currentTime);
    };

    audioElement.addEventListener('pause', handlePause);
    audioElement.addEventListener('play', handlePlay);
    audioElement.addEventListener('volumechange', handleVolumeChange);
    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      // Limpia los eventos cuando el componente se desmonta
      audioElement.removeEventListener('pause', handlePause);
      audioElement.removeEventListener('play', handlePlay);
      audioElement.removeEventListener('volumechange', handleVolumeChange);
      audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioContext, analyser]);

  const handlePlayButton = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    } 
  }; 
  const handlePrev = () => { 
    audioRef.current.pause();
    setIsPlaying(false)
    const newIndex = (currentStationIndex - 1 + radioStations.length) % radioStations.length;
    setCurrentStationIndex(newIndex) 
     audioRef.current.src = radioStations[currentStationIndex].listen 
     audioRef.current.oncanplay = () => {
      audioRef.current.play(); 
    };
  };

  const handleNext = () => {
    audioRef.current.pause();
    setIsPlaying(false)
    const newIndex = (currentStationIndex + 1) % radioStations.length;  
    setCurrentStationIndex(newIndex)
    audioRef.current.src = radioStations[currentStationIndex].listen  
    audioRef.current.oncanplay = () => {
      audioRef.current.play(); 
    };
  };
  return (<> 
    <nav className="fixed bottom-0 left-0 right-0 bg-purple-900 bg-player text-white"> 
    <div ref={fieldsetRef} style={{ height: '40px' }} className='mt-2 md:mt-0'>
                {audioContext && analyser ? <P5Wrapper audioContext={audioContext} analyser={analyser} fieldsetWidth={fieldsetWidth} /> : null} 
              </div> 
        <div class="md:grid md:grid-cols-[1fr_11fr] pb-4">
          <div  class=" flex items-center  "> 
            <div className='flex md:pt-0 pt-2  '>
              <img src={`${radioStations[currentStationIndex].id}_${radioStations[currentStationIndex].cover}`} alt="User Avatar" class="self-center h-10 w-10 rounded ml-2"/>
              <div class="ml-2 flex   md:content-center"> 
              <button  onClick={handlePrev}> 
                  <IconPrev size={28} />
              </button>  
              <button  onClick={handlePlayButton}>
                  {isPlaying ? <IconPause size={42} /> : <IconPlay size={42} /> }
              </button>  
              <button  onClick={handleNext}> 
                  <IconNext size={28} />
              </button>  
              <small className='ml-5 self-center md:hidden '> {isPlaying ?  'Reproduciendo: ': 'Cargando.....'} {radioStations[currentStationIndex]?.name}</small>
            </div>
          </div>
          </div>  
          <div className="w-100">  
               
          <div className='flex justify-between content-center p-1'>
          <div className='inline-block'>
            <input
                className='align-bottom slider'
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={(e) => {
                  audioRef.current.volume = e.target.value;
                  setVolume(e.target.value);
                }}
              /> <small>Volumen: {volume}</small>
          </div>
          <p className='ml-5 self-center hidden md:inline-block '> {isPlaying ?  'Estás Echuchando: ': 'Cargando.....'} {radioStations[currentStationIndex]?.name}</p>
          <small className='mt-1'>Playing: {isPlaying.toString()} Time: {formatTime(elapsedTime)} </small>    
          </div>
          </div> 
        </div>
        <audio ref={audioRef} controls crossOrigin="anonymous" style={{ display: 'none' }} src={radioStations[currentStationIndex].listen}>
          <source src={radioStations[currentStationIndex].listen} type="audio/mpeg" />
          Tu navegador no soporta la reproducción de audio en HTML5.
        </audio>
    </nav>  
    </>
  );
};

export default AudioPlayer;
