import React from 'react'; 
import CryptoList from './Crypto';    
import TelegramWidget from './TelegramWidget';
import { Link } from 'react-router-dom';
const SidebarRight = () => { 
  return (
    <div className=''>
        <div className='siderbar px-1 '>  
          <CryptoList /> 
            <TelegramWidget
              discussion="VoltSync/5"
              width="100%"
              dark="1"
              userpic= {"true"}
            />    
            <TelegramWidget
            telegramDiscussion="VoltSync/5"
            width="100%"
            dark="1"
            userpic={"true"}
          />    
          <div className='text-center mt-6 text-white'>
            <Link to="/terminos"> Terminos y condiciónes </Link>
          </div>
        </div>
    </div>
  );
};

export default SidebarRight;
