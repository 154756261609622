import React, { useEffect, useState} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
const { format } = require('timeago.js');

const CACHE_KEY = 'newsArticles';
const CACHE_TIME_KEY = 'newsArticlesCacheTime';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds
const INITIAL_LOAD = 10; // Initial articles to load
const LOAD_MORE = 10; // Articles to load on scroll

const NewsComponent = ({article} ) => {
    const [articles, setArticles] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [visibleCount, setVisibleCount] = useState(INITIAL_LOAD);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const cachedArticles = localStorage.getItem(CACHE_KEY);
                const cacheTime = localStorage.getItem(CACHE_TIME_KEY);
                const isCacheValid = cacheTime && (Date.now() - cacheTime < CACHE_DURATION);

                if (isCacheValid && cachedArticles) {
                    setArticles(JSON.parse(cachedArticles));
                } else {
                    const response = await axios.get('https://newsapi.org/v2/everything', {
                        params: {
                            q: '',
                            language: 'es',
                            sortBy: 'publishedAt',
                            domains: 'rt.com,xataka.com,dw.com,engadget.com,cnnespanol.cnn.com,news.google.com,infobae.com',
                            apiKey: '77f91fe3e5bc4dcc9cd238a2d320caca'
                        }
                    });
                    setArticles(response.data.articles);
                    localStorage.setItem(CACHE_KEY, JSON.stringify(response.data.articles));
                    localStorage.setItem(CACHE_TIME_KEY, Date.now());
                }
            } catch (error) {
                console.error('Error fetching news:', error);
            }
        };

        fetchNews();
    }, []);
    const fetchMoreData = () => {
        const newVisibleCount = visibleCount + LOAD_MORE;
        if (newVisibleCount >= articles.length) {
            setHasMore(false); // No more articles to load
        }
        setVisibleCount(newVisibleCount);
    };
    return (
        <div>
        <InfiniteScroll
            dataLength={visibleCount}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<h4>Cargando...</h4>}
            endMessage={<p>No hay más artículos para mostrar.</p>}
            scrollThreshold={1}
        >
            <div className='grid grid-cols-1 md:grid-cols-2 gap-1 md:gap-4'>
                {articles.slice(0, visibleCount).map((article, index) => (
                    <div key={index} className="mb-1 shadow-lg rounded-lg overflow-hidden relative">
                        <img src={article.urlToImage} alt={article.title} className="w-full h-60 object-cover" />
                        <div>
                            <div className="widget p-4 absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white">
                                <h3 className="text-lg font-bold">
                                    <Link to={{ pathname: `/article/${index}` }} state={article}>
                                        {article.title}
                                    </Link>
                                </h3>
                                <p className="text-sm">{article.author} - {format(article.publishedAt)}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </InfiniteScroll>
    </div>
    );
};

export default NewsComponent;
