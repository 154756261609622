import React,{useState}  from 'react';
import Sketch from 'react-p5';

  
let widthLayout;
let heightLayout;  
 
 
const P5Wrapper = ({ audioContext ,analyser ,fieldsetWidth}) => {  
    const [dataArray, setDataArray] = useState(null);
    const [bufferLength, setBufferLength] = useState(0);
   
    const estilo = window.getComputedStyle(fieldsetWidth); 

    widthLayout = fieldsetWidth.clientWidth;
    heightLayout = fieldsetWidth.clientHeight - parseFloat(estilo.paddingTop);
      
     const setup = (p5, canvasParentRef) => { 
        let bufferLength = analyser.frequencyBinCount;
        let dataArray = new Float32Array(bufferLength); 
        setBufferLength(bufferLength);
        setDataArray(dataArray);
       
         p5.createCanvas(widthLayout, heightLayout).parent(canvasParentRef);  
        // console.log(canvasInstance);
         p5.angleMode(p5.DEGREES);  
        
      }; 
      
      const draw = (p5) => {
        p5.clear();      

        if (dataArray instanceof Float32Array) { 
            analyser.getFloatTimeDomainData(dataArray);
            p5.translate(0, heightLayout /2); // Mueve el origen al medio de la altura 
            p5.stroke(p5.random(0,255), p5.random(20,255), p5.random(20,255))
            p5.strokeWeight(2);
            p5.noFill();
            p5.beginShape();  
            
            for (let i = 0; i < bufferLength; i++) {   
                let x = p5.map(i, 0, bufferLength - 1, 0, widthLayout);
                // Ajusta el mapeo de 'r' y añade un desplazamiento
                let y =  p5.map(dataArray[i], -1, 1, -heightLayout/2 , heightLayout/2  ) ;  
               
                p5.vertex(x, y); 
            } 
            p5.line(0, heightLayout /2, widthLayout );
            p5.endShape();
        }
    };
    
     
     
     
    
    
    
      return <Sketch setup={setup} draw={draw} />;
};

export default P5Wrapper;
