import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BitcoinBalance = ({ address }) => {
  const [balance, setBalance] = useState(null);

  useEffect(() => {
    const cachedBalance = localStorage.getItem(`${address}_balance`);
    const cachedTimestamp = localStorage.getItem(`${address}_timestamp`);

    if (cachedBalance && cachedTimestamp) {
        const currentTime = new Date().getTime();
        const cachedTime = parseInt(cachedTimestamp, 10);
        const hoursDifference = (currentTime - cachedTime) / (1000 * 60 * 60);
    
        if (hoursDifference < 24) {
          setBalance(cachedBalance);
          return;
        } else {
          // Si han pasado más de 24 horas, eliminar los datos de caché
          localStorage.removeItem(`${address}_balance`);
          localStorage.removeItem(`${address}_timestamp`);
        }
    }

    axios.get(`https://blockstream.info/api/address/${address}`)
      .then(response => {
        const fundedSum = response.data.chain_stats.funded_txo_sum || 0;
        const spentSum = response.data.chain_stats.spent_txo_sum || 0;
        const calculatedBalance = (fundedSum - spentSum) / 100000000;

        // Store the balance and timestamp in localStorage
        localStorage.setItem(`${address}_balance`, calculatedBalance);
        localStorage.setItem(`${address}_timestamp`, new Date().getTime().toString());

        setBalance(calculatedBalance);
      })
      .catch(error => {
        //console.error('Error:', error);
      });
  }, [address]); 

  const cbalance = balance === null ? 0 : balance;
  const percentage = (cbalance / 0.05) * 100;
  const  cappedPercentage = Math.min(percentage, 100);

  return (
    <div className='bg-white shadow-lg p-5 mb-3 text-center'>
       <div className='glitch text-5xl'>Donaciones 💸</div>
       <p>No hay limites Para tu donación</p>
       <small>Proyect DeepLearnig NVIDIA RTX 3090  & AMD Ryzen 9 7950X</small>
     <div class="flex justify-between py-2">
     {balance !== null ? (
        <p>{balance}BTC</p>    
          ) : (
        <p>Loading balance...</p>
      )}
      <p>{cappedPercentage.toFixed(2)}% </p>
    </div> 
    <div class="bg-gray-200 w-full h-5 rounded-full">
      <div class="bg-purple-500 h-full rounded-full" style={{width:cappedPercentage+`%`}}></div> 
    </div>

      
    </div>
  );
};

export default BitcoinBalance;
