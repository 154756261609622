import React,{useState} from 'react';
import BitcoinBalance from './BitcoinBalance';
const CryptoList = () => {
    const address = 'bc1qrz8mhsv4ppdss5wjtdlkgg529ek72eah2p4y3g';
    const cryptocurrencies = [
        {
            "name": "Bitcoin",
            "symbol": "BTC",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
            "exchange": "https://www.binance.com/es/trade/BTC_USDT",
            "wallet": "bc1qrz8mhsv4ppdss5wjtdlkgg529ek72eah2p4y3g"
        },
        {
            "name": "Monero",
            "symbol": "XMR",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/328.png",
            "exchange": "https://www.binance.com/es/trade/XMR_USDT",
            "wallet": "4AYx3HaYmF3JhiQjtttcNDD7CLoPVSMdrJHwvqAQ9HpMTGWzwWpchLVYwRoDcqzDbf5tVHzWLJJUdGanrJepejMbKodCDS9"
        },
        {
            "name": "Zcash",
            "symbol": "ZEC",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/1437.png",
            "exchange": "https://www.binance.com/es/trade/ZEC_USDT",
            "wallet": "t1P4NxNMGHU4h1wv356QtkhKZ7srZdbzvB8"
        },
        {
            "name": "Dash",
            "symbol": "DASH",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/131.png",
            "exchange": "https://www.binance.com/es/trade/DASH_USDT",
            "wallet": "XxWAGBfWqiuTNv6aQirLiGcE9FiizymHXs"
        },
        {
            "name": "Binance Coin",
            "symbol": "BNB",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
            "exchange": "https://www.binance.com/es/trade/BNB_USDT",
            "wallet": "0x5a8b0368a4Cf36c7a5b00852A9dBBfe29A390B7F"
        },
        {
            "name": "Polkadot",
            "symbol": "DOT",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png",
            "exchange": "https://www.binance.com/es/trade/DOT_USDT",
            "wallet": "15PcimVdY9aTViu3A3PHWZ4Q86Pk46c784x1Zq7ayQfbQEEk"
        },
        {
            "name": "Solana",
            "symbol": "SOL",
            "icon": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
            "exchange": "https://www.binance.com/es/trade/SOL_USDT",
            "wallet": "DTE59J8LTZCoXEB6Ee4fsbFxCy23gmxUnMTw1sWgNwJy"
        }
    ];
    
    const [selectedCrypto, setSelectedCrypto] = useState(cryptocurrencies[0]);
    const [copyButtonText, setCopyButtonText] = useState('Copy Wallet Address');

    const handleCryptoClick = (crypto) => {
        console.log(crypto);
        
        setSelectedCrypto(crypto); 
        setCopyButtonText('Copiar Direción '+crypto.symbol+''); 
        //console.log(crypto.symbol);
    };
    
    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopyButtonText('Copiado'); 
    };
    return (
        <div className='text-slate-800'>
         <div  className='shadow-lg bg-white rounded p-5 my-3 text-center'> 
            <h2 className='text-xl font-semibold'>Crypto Donaciones</h2>
            <small className='mb-3 block'>Elige una criptomoneda, No existe limite para tu donación</small>  
            <div className='cryptoicons flex flex-wrap flex justify-between '>
                {cryptocurrencies.map((crypto, index) => (
                    <img width={30}
                        height={30}
                        key={index}
                        src={crypto.icon}
                        alt={`${crypto.name} icon`}
                        title={`${crypto.name} icon`}

                        className="mr-2  rounded-full crypto-image cursor-pointer"
                        onClick={() => handleCryptoClick(crypto)} 
                    />
                ))}
            </div>
            <div className="flex flex-wrap flex justify-between ">
            
            {selectedCrypto && (
                <div className="w-full mt-4">
                    <h2 className="text-lg font-semibold">{selectedCrypto.name} </h2>
                
                    <p className='mb-4 break-all p-2 rounded shadow border'>{selectedCrypto.wallet}</p>
                    <div className='flex justify-between text-sm'>
                    <div className="bg-blue-800  btn-primary  inline-block">
                    {selectedCrypto.symbol}</div>
                    <button
                            className="btn-primary bg-gray-800 hover:bg-gray-700 ml-2"
                            onClick={() => handleCopyToClipboard(selectedCrypto.wallet)}
                        >
                        {copyButtonText}

                        </button>
                    </div>
                </div>
            )}
            </div> 
    
            <a className="btn-primary bg-blue-800 hover:bg-blue-700 block mt-2 text-center w-100"
            rel="noreferrer"
            href={`https://www.youtube.com/results?search_query=comprar+crypto+en+mi+pais+${selectedCrypto.symbol}`}
            target="_blank"
            >
            ¿Dónde comprar? {selectedCrypto.symbol}
            </a>


             
        </div>
        <BitcoinBalance address={address}/>
        </div>

    
    );
};

export default CryptoList;
